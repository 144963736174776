import { Avatar, Button, Col, Row, Table, Modal, Select, message, Spin, Form, Card, Rate, DatePicker, Space } from 'antd'
import { UserOutlined, CaretLeftOutlined } from '@ant-design/icons';
import React, { useEffect, useState, useRef } from 'react'
import girlPhoto from '../assets/images/girl.png'
import logoDel from '../assets/images/delete.png'
import logoChat from '../assets/images/A1.png';
import logoUser from '../assets/images/a2.png';
import logoVideo from '../assets/images/a3.png';
import logoComent from '../assets/images/a4.svg';
import { getDataCallContact, getDataReports, postCall } from '../components/CommonApiCall'
import moment from 'moment';
import { fileUrl } from '../constants/const';
import Search from 'antd/es/input/Search';
import ModalQuestion from '../components/ModalQuestion';
import AppUtils from '../Utils/AppUtils';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';
const { RangePicker } = DatePicker;



function UserReporting() {
  // const currentDate = moment();  // Get current date

  const [reportType, setReportType] = useState("user")
  const [userData, setUserData] = useState([]);
  const [openUserDetails, setOpenUserDetails] = useState(false);
  const [detailUserData, setDetailUserData] = useState(false);
  const [showUser, setShowUser] = useState([]);
  const [showDetails, setShowDetails] = useState([]);
  const [openDelModal, setOpenDelModal] = useState(false)
  const [openSuspendModal, setOpenSuspendModal] = useState(false)
  const [openUnsuspendModal, setOpenUnsuspendModal] = useState(false)
  const [searchText, setSearchText] = useState();
  const [delId, setDelId] = useState('');
  const [suspendId, setSuspendId] = useState('');
  const [unsuspend, setUnsuspend] = useState('');
  const [warning, setWarning] = useState('');
  const [warningData, setWarningData] = useState([]);
  const [warningModal, setWarningModal] = useState(false);
  const [loading, setLoading] = useState();
  const [openWarningList, setOpenWarningList] = useState(false);
  const [activeUsers, setActiveUsers] = useState([]);
  const [cats, setCats] = useState([]);
  const [form] = Form.useForm();
  const [filter, setFilter] = useState({
    user: '',
    category: '',
    warning: "",
    startDate: "",
    endDate: ""
  })

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: ['5', '10', '25', '50', '100'],
    position: ['bottomCenter'],
    size: 'small'
  });

  const getWarningData = async (showUser, reportedData) => {
    setLoading(true)
    let data = {
      reportId: reportedData?._id,
    }
    const successCb = (response) => {
      setLoading(false)
      let warningCount = response?.data?.data?.results.length
      const data = response?.data?.data?.results.map(item => {
        return item
      });
      setWarningData({ warningCount, data });
    }
    const failureCb = (response) => {
      setLoading(false)
      setWarningData('')
    }
    getDataCallContact(`reports/warning?reportId=${data?.reportId}`, successCb, failureCb);
  }
  console.log('active users', activeUsers)
  const handleViewClick = (record) => {
    console.log("record", record)
    setOpenUserDetails(true)
    let user = detailUserData.filter((i) => i?._id == record?._id)
    setShowUser(user)
    setLoading(true)
    console.log("detailUserData", user)
    let id;
    if (reportType == "stream") {
      id = user?.[0]?.streamId
    }
    if (reportType == "user") id = user?.[0]?.reportedToData?._id
    if (reportType == "chat") id = user?.[0]?.chatId
    if (reportType == "comment") id = user?.[0]?.commentId
    if (reportType == "group") id = user?.[0]?.groupId
    if (reportType == "rating") id = user?.[0]?.ratingByData?._id
    let data = {
      reportId: id,
      reportType,
    }
    let url = `/reports/reportByUser?page=${pagination?.current}&limit=${pagination?.pageSize}`
    const successCb = (res) => {
      setLoading(false)
      getData()
      setShowUser(res?.data?.data?.[0]?.results)
      setShowDetails(res?.data?.reportedData)
      if (reportType != "rating")
        getWarningData(res?.data?.data?.[0]?.results, res?.data?.reportedData)
    }
    const failureCb = (res) => {
      setLoading(false)
    }
    postCall(data, url, successCb, failureCb)
  }

  const getActiveUsers = (searchQuery) => {
    setLoading(true)
    const successCb = (response) => {
      setLoading(false)

      let results = response?.data?.results ?? []
      let activeUsers = results.map(item => {
        // Modify each item as per your needs
        return {
          // Example: assuming `id` and `name` fields exist in the objects
          label: `${item.firstName} ${item.lastName}`,
          value: item._id
        };
      });

      setActiveUsers(activeUsers)
    }
    const failureCb = (response) => {
      setLoading(false)
      console.log('active user failure response ', response)
      setActiveUsers([])
    }
    let url = `admin/activeUsers?limit=100&page=1`
    if (searchQuery && searchQuery != '') {
      url = `admin/activeUsers?limit=100&page=1&search=${searchQuery}`
    }
    getDataCallContact(url, successCb, failureCb);
  }


  // Debounced function to avoid excessive API calls
  const debouncedGetActiveUsers = debounce((searchQuery) => {
    // setLoading(true);

    const successCb = (response) => {
      setLoading(false);

      let results = response?.data?.results ?? [];
      let activeUsers = results.map(item => {
        return {
          label: `${item.firstName} ${item.lastName}`, // assuming firstName and lastName fields exist
          value: item._id // assuming _id is the unique identifier
        };
      });

      console.log('search active users>>>>>>>>>>>>>>', activeUsers);
      setActiveUsers([...activeUsers]); // update the options for Select
    };

    const failureCb = (response) => {
      // setLoading(false);
      console.log('active user failure response', response);
      setActiveUsers([]); // clear options in case of failure
    };

    // let url = `admin/activeUsers?limit=100&page=1`;
    if (searchQuery && searchQuery !== '') {
      let url = `admin/activeUsers?limit=100&page=1&search=${searchQuery}`;
      getDataCallContact(url, successCb, failureCb);
    }


  }, 300); // Adjust debounce delay as needed (300ms is common)


  const getCategories = () => {
    setLoading(true)
    const successCb = (response) => {
      setLoading(false)

      let results = response?.data ?? []
      let cats = results.map(item => {
        // Modify each item as per your needs
        return {
          // Example: assuming `id` and `name` fields exist in the objects
          label: item.categoryName,
          value: item._id
        };
      });
      setCats([...cats])
      console.log('category ', cats)
    }
    const failureCb = (response) => {
      setLoading(false)
      setCats([])
      console.log('categories failure response ', response)
    }
    getDataCallContact(`workouts/list?page=1&limit=100`, successCb, failureCb);
  }

  let columns = [
    {
      title: 'Reports',
      dataIndex: 'Reports',
      key: 'Reports',
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
      align: 'right',
      render: (_, record) => {
        return (
          <>
            {(reportType == "stream" && record?.thumbnail) && <Button className='viewButton' onClick={() => handleViewClick(record)}>View</Button>}
            {(reportType == "user" || reportType == "chat" || reportType == "comment" || reportType == "group") && <Button className='viewButton' onClick={() => handleViewClick(record)}>View</Button>}
          </>)
      },
    },
  ];
  if (reportType == "user") {
    columns = [
      {
        title: 'Users',
        dataIndex: 'users',
        key: 'Users',
      },
      {
        title: 'Reports',
        dataIndex: 'Reports',
        key: 'Reports',
      },
      //  {
      //    title: 'Report Reason',
      //    dataIndex: 'reason',
      //    key: 'Report_Message',
      //  },
      {
        title: 'Action',
        dataIndex: 'Action',
        key: 'Action',
        render: (_, record) => {
          return (
            <>
              <Button className='viewButton' onClick={() => handleViewClick(record)}>View</Button>
            </>)
        },
      },
    ];
  } else if (reportType == "stream") {
    columns.unshift({
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      // render: (thumb, record) => {
      //   return (
      //     <>
      //       <img src={thumb} alt="" style={{ width: "80px", height: "80px" }} />
      //     </>)
      // },
    },)
    columns.unshift({
      title: 'Video',
      dataIndex: 'video',
      key: '.video',
      render: (video, record) => {
        console.log("video colm", video, record)
        return (
          <>
            {video &&
              <video controls width="150px" height="100px">
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            }
          </>)
      },
    },)


  } else if (reportType == "chat") {
    columns.unshift({
      title: 'Chat Reported',
      dataIndex: 'reason',
      key: 'reason',
    },)
    columns.unshift({
      title: 'Chat',
      dataIndex: 'desc',
      key: 'desc',
    },)
  }
  else if (reportType == "group") {
    columns.unshift({
      title: 'Reported',
      dataIndex: 'reason',
      key: 'reason',
    },)
    columns.unshift({
      title: 'Group',
      dataIndex: 'desc',
      key: 'desc',
    },)
  }
  else if (reportType == "rating") {
    columns = [
      {
        title: 'Review',
        dataIndex: 'reason',
        key: 'reason',
      },
      {
        title: 'Review Reported',
        dataIndex: 'desc',
        key: 'desc',
      },
      {
        title: 'Action',
        dataIndex: 'Action',
        key: 'Action',
        render: (_, record) => {
          return (
            <>
              <Button className='viewButton' onClick={() => handleViewClick(record)}>View</Button>
            </>)
        },
      }]


  }
  else {
    columns.unshift({
      title: 'Commment Reported',
      dataIndex: 'reason',
      key: 'reason',
    },)
    columns.unshift({
      title: 'Commment',
      dataIndex: 'desc',
      key: 'desc',
    },)
  }

  useEffect(() => {
    getData();
  }, [pagination?.current, pagination?.pageSize, reportType, searchText])

  useEffect(() => {
    getActiveUsers()
    getCategories()
  }, [])

  //** Called to fetch the users data record **//
  const getData = async () => {
    setLoading(true)
    const successCb = (response) => {
      setLoading(false)
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: response?.data?.data?.[0]?.totalResults,
      }));
      let data = [];
      response?.data?.data?.[0]?.results.forEach(item => {
        let firstName = item?.reportedToData?.firstName ?? '--';
        let lastName = item?.reportedToData?.lastName ?? '--';
        if (reportType == "stream") {
          console.log("55555555555555", item)
          if (item?.streamByData.length > 0) {
            var videoLink;
            if (item?.streamByData?.streamType == "pre") videoLink = `${fileUrl}${item?.streamByData[0].video}`;
            else videoLink = `${fileUrl}streams/${item?.streamByData[0].video}`
            console.log("video link 555555", videoLink, item?.streamByData?.[0]?.streamType)


            // var video = `${fileUrl}${item?.streamByData[0].video}` ?? ''

            var thumbnail = `${fileUrl}${item?.streamByData[0].thumbnail}` ?? '';
            var title = item?.streamByData[0].title
          } else return;
        }
        data.push({
          _id: item?._id,
          users: `${firstName} ${lastName}`,
          reason: item?.reason,
          desc: item?.desc,
          Reports: item?.count,
          thumbnail: thumbnail,
          video: videoLink,
          title: title,
        })
      });
      setUserData(data);
      setDetailUserData(response?.data?.data?.[0]?.results)
      console.log("data response", response?.data?.data?.[0]?.results, data)
    }
    const failureCb = (response) => {
      setLoading(false)
      setUserData('')
      setPagination({
        current: 1,
        total: 0,
        pageSize: 5,
      });
    }
    getDataReports(pagination?.current, pagination?.pageSize, searchText, "reports", successCb, failureCb, reportType);
  }

  const handleTypeChange = (value) => {
    setReportType(value);
    setPagination({
      current: 1,
      pageSize: 10,
      pageSizeOptions: ['5', '10', '25', '50', '100'],
      // showSizeChanger: true,
      position: ['bottomCenter'],
      size: 'small'
    })
  }

  const handleSuspend = (user) => {
    setSuspendId(user)
    setOpenSuspendModal(true)
  }
  const handleWarning = (user) => {
    setWarning(user)
    setWarningModal(true)
  }

  const handleWarningFinish = (values) => {
    let reportUser;
    if (reportType == "user") reportUser = warning?.reportedTo;
    if (reportType == "chat") reportUser = showDetails?.chatBy?._id;
    if (reportType == "comment") reportUser = warning?.commentByData?._id;
    if (reportType == "stream") reportUser = warning?.streamData?.user;
    if (reportType == "group") reportUser = warning?.groupByData?._id;


    let data = {
      reportUser: reportUser,
      reportId: warning?._id,
      title: values?.Name,
      message: values?.Message
    }
    let url = '/reports/warning'
    const successCb = (res) => {
      setLoading(false)
      form.resetFields()
      setWarningModal(false)
      getData()
      message.success("Sent Warning Successfully")
    }
    const failureCb = (res) => {
      setLoading(false)
    }
    postCall(data, url, successCb, failureCb)
  }



  const handleUnsuspend = (user) => {
    setUnsuspend(user)
    setOpenUnsuspendModal(true)
  }
  const handleDelete = (id) => {
    setDelId(id)
    setOpenDelModal(true)
    console.log(id)
  }

  const onDeleteOk = () => {
    setLoading(true)
    let data = {
      rating: delId,
    }

    let url = '/reports/deleteRating'

    const successCb = (res) => {
      setLoading(false)
      setDelId(null)
      setOpenDelModal(false)
      setOpenUserDetails(false);
      setWarningData(null)
    }
    const failureCb = (res) => {
      setLoading(false)
      console.log("failure ", res)
      let message = res?.data?.data?.message ?? ""
      if (message != "") {
        toast.error(message)
      }
    }
    postCall(data, url, successCb, failureCb)
  }
  const onSuspendOk = () => {
    setLoading(true)
    let reportUser;
    if (reportType == "user") reportUser = suspendId?.reportedTo;
    if (reportType == "chat") reportUser = showDetails?.chatBy?._id;
    if (reportType == "comment") reportUser = suspendId?.commentByData?._id;
    if (reportType == "stream") reportUser = suspendId?.streamData?.user;
    if (reportType == "group") reportUser = suspendId?.groupByData?._id;
    let data = {
      reportUser: reportUser,
      reportId: suspendId?._id,
    }
    if (reportType == "group") data.reportGroup = suspendId?.groupId;
    let url = '/reports/suspendReportedUser'
    const successCb = (res) => {
      setLoading(false)
      getData()
      setOpenUserDetails(false)
      message.success("User Suspended Successfully")
      setSuspendId(null)
      setOpenSuspendModal(false)
    }
    const failureCb = (res) => {
      setLoading(false)
      message.error(res?.data?.message)
      setOpenSuspendModal(false)

    }
    postCall(data, url, successCb, failureCb)
  }

  //*** to handle the Search Value ***//
  const handleSearch = (value) => {
    setSearchText(value);
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: 1,
    }));
  };
  const onUnsuspendOk = () => {
    setLoading(true)
    let reportUser;
    if (reportType == "user") reportUser = unsuspend?.reportedTo;
    if (reportType == "chat") reportUser = showDetails?.chatBy?._id;
    if (reportType == "comment") reportUser = unsuspend?.commentByData?._id;
    if (reportType == "stream") reportUser = unsuspend?.streamData?.user;
    if (reportType == "group") reportUser = unsuspend?.groupByData?._id;
    let data = {
      reportUser: reportUser,
      reportId: unsuspend?._id,
      unSuspend: "1"
    }
    if (reportType == "group") data.reportGroup = unsuspend?.groupId;
    let url = '/reports/suspendReportedUser'
    const successCb = (res) => {
      setLoading(false)
      getData()
      setOpenUserDetails(false)
      setOpenUnsuspendModal(false)
      setUnsuspend(null)
      message.success("User Unsuspended Successfully")
    }
    const failureCb = (res) => {
      setLoading(false)
      setOpenUnsuspendModal(false)
      setOpenUserDetails(false)
      message.error("Something wrong happened, Try again")
      message.error(res?.data?.message)
    }
    postCall(data, url, successCb, failureCb)
  }


  // *** Page change ******//
  const handlePageChange = (page, pageSize) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: page,
      pageSize: pageSize,
    }));
  };
  const style = {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    color: '#6C3BD5',
    border: "3px solid #6C3BD5",
    padding: "15px",
    // margin : "5px",
    textAlign: "center",
    borderRadius: "10px",
    cursor: "pointer"
  }
  const styleUnselected = {
    padding: "15px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    cursor: "pointer"
  }
  const conditionalStyleUser = reportType === 'user' ? style : styleUnselected;
  const conditionalStyleVideo = reportType === 'stream' ? style : styleUnselected;
  const conditionalStyleChat = reportType === 'chat' ? style : styleUnselected;
  const conditionalStyleComments = reportType === 'comment' ? style : styleUnselected;
  const conditionalStyleGroup = reportType === 'group' ? style : styleUnselected;
  const conditionalStyleReview = reportType === 'rating' ? style : styleUnselected;

  const getRatedByFullName = (showDetails) => {
    let firstName = showDetails?.rating?.rated_by?.firstName ?? ""
    let lastName = showDetails?.rating?.rated_by?.lastName ?? ""

    if (firstName != '' && lastName != '') {
      return `${firstName} ${lastName}`
    }
    return "--"
  }
  const getRatedToFullName = (showDetails) => {
    let firstName = showDetails?.rating?.rated_to?.firstName ?? ""
    let lastName = showDetails?.rating?.rated_to?.lastName ?? ""
    if (firstName != '' && lastName != '') {
      return `${firstName} ${lastName}`
    }
    return "--"
  }

  const handleCategoryChange = (value) => {
    console.log(`selected category ${value}`);

    setFilter({ ...filter, category: value })
  };
  const handleUserChange = (value) => {
    console.log(`selected user ${value}`);

    setFilter({ ...filter, user: value })
  };
  const handleWarningChange = (value) => {
    console.log(`selected warning ${value}`);

    setFilter({ ...filter, warning: value })
  }

  const onDateChange = (dates, dateStrings) => {
    const [startDate, endDate] = dateStrings;  // dateStrings contains the formatted dates as strings
    console.log("Start Date:", startDate);
    console.log("End Date:", endDate);

    setFilter({ ...filter, startDate, endDate })
  };

  return (
    <div className='p-2'>
      {loading && <Spin size="large" className='loader' />}
      {!openUserDetails ?
        <>
          <Row className='justify-content-between'>
            <h4 className=' fw-bold'>Reporting</h4>
            <Search
              placeholder="Search by name.."
              onSearch={handleSearch}
              enterButton
              allowClear
              style={{ width: "30%", float: "right", marginBottom: "10px" }}
            />
          </Row>
          <Row className="my-4 d-flex justify-content-around Maincard py-2">
            <Col xs={12} sm={6} md={4} lg={4} style={conditionalStyleUser}>
              <h6 className='fw-bold m-0' onClick={() => handleTypeChange('user')} >User Reports</h6>
              <img src={logoUser} alt="" style={{ width: "2.5rem" }}></img>
            </Col>
            <Col xs={12} sm={6} md={4} lg={4} style={conditionalStyleVideo}>
              <h6 className='fw-bold m-0' onClick={() => handleTypeChange('stream')} >Video Report
              </h6>
              <img src={logoVideo} alt="" style={{ width: "2.5rem" }}></img>
            </Col>
            <Col xs={12} sm={6} md={4} lg={4} style={conditionalStyleChat}>
              <h6 className='fw-bold m-0' onClick={() => handleTypeChange('chat')} >Chat Report
              </h6>
              <img src={logoChat} alt="" style={{ width: "2.5rem" }}></img>
            </Col>
            <Col xs={12} sm={6} md={4} lg={4} style={conditionalStyleComments}>
              <h6 className='fw-bold m-0' onClick={() => handleTypeChange('comment')} >Comment Report
              </h6>
              <img src={logoComent} alt="" style={{ width: "2.5rem" }}></img>
            </Col>
            <Col xs={12} sm={6} md={4} lg={4} style={conditionalStyleGroup}>
              <h6 className='fw-bold m-0' onClick={() => handleTypeChange('group')} >Group Report
              </h6>
              <img src={logoUser} alt="" style={{ width: "2.5rem" }}></img>
            </Col>
            <Col xs={12} sm={6} md={4} lg={4} style={conditionalStyleReview}>
              <h6 className='fw-bold m-0' onClick={() => handleTypeChange('rating')} >Review Report
              </h6>
              <img src={logoUser} alt="" style={{ width: "2.5rem" }}></img>
            </Col>
          </Row>
          {reportType === 'stream' && (


            <Row className="my-4 d-flex  Maincard py-2">
              <Col xs={24} sm={12} md={12} lg={12} style={{ paddingLeft: "2.5rem" }}>

                <div className='d-flex flex-direction-row align-items-center'>
                  <p className='fw-bold m-0' style={{ fontWeight: 'bold' }} onClick={() => handleTypeChange('user')} >Filter By:</p>
                  <div className='ps-3'>
                    <Select
                      // defaultValue="lucy"
                      style={{ width: 200 }}
                      placeholder='Category'
                      onChange={handleCategoryChange}
                      options={cats}
                    />
                  </div>
                  <div className='ps-3'>
                    <Select
                      showSearch
                      placeholder="Select an option"
                      optionFilterProp="children"
                      onChange={handleUserChange}
                      loading={loading}
                      style={{ width: 200 }}
                      filterOption={(input, option) =>
                        (option?.children || '').toLowerCase().includes(input.toLowerCase())
                      }
                    >
                      {activeUsers.map((opt) => (
                        <Select.Option key={opt.value} value={opt.value}>
                          {opt.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                  <div className='ps-3'>
                    <Select
                      // defaultValue="lucy"
                      style={{ width: 200 }}
                      placeholder='Warning'
                      onChange={handleWarningChange}
                      options={[
                        { value: '0', label: '0 Warning' },
                        { value: '1', label: '1 Warning' },
                        { value: '2', label: '2 Warnings' },
                        { value: '3', label: '3 Warnings' },
                      ]}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} style={{ paddingLeft: "1.5rem" }}>
                <div className='d-flex flex-direction-row align-items-center'>
                  <p className='fw-bold m-0' style={{ fontWeight: 'bold' }} onClick={() => handleTypeChange('user')} >Duration</p>
                  <div className='ps-3'>
                    <RangePicker onChange={onDateChange} />

                  </div>
                  <div className='ps-3'>
                    <Button className='viewButton' onClick={() => console.log('filter ', filter)}>Search</Button>
                  </div>
                </div>
              </Col>
            </Row>
          )}
          <Table
            dataSource={userData}
            columns={columns}
            scroll={{ x: "250px" }}
            pagination={{
              ...pagination,
              onChange: handlePageChange,
            }} />
        </>
        :
        <>
          {showUser?.length > 0 && showUser?.map((user, index) => {
            AppUtils.showMessage("showUser ", showDetails)
            let imagesrc = '';
            if (reportType == "stream" && user?.streamData?.thumbnail) imagesrc = `${fileUrl}${user?.streamData?.thumbnail}`
            if (reportType == "user" && user?.reportedTo?.profilePic) imagesrc = `${fileUrl}${user?.reportedTo?.profilePic}`
            const suspendCheck = <Button type='primary' danger onClick={() => handleSuspend(user)}>Suspend</Button>
            const unsuspendCheck = <Button type='primary' className='ms-1' style={{ backgroundColor: "green" }} onClick={() => handleUnsuspend(user)}>UnSuspend</Button>
            const reviewDelete = <Button type='primary' danger onClick={() => handleDelete(showDetails?.rating?._id ?? "")}>Delete Review</Button>
            const reviewDeleted = <Button type='link'  >Review Deleted</Button>
            return (

              <>

                <Row className='d-flex justify-content-between  p-1'>
                  <Col xs={24} sm={16} md={16} lg={16}>
                    <h4 className='fw-bold'>User Reporting Details</h4>
                  </Col>
                  <Col xs={24} sm={8} md={8} lg={8} className='d-flex justify-content-end'>
                    <Button type='primary' className='purpleButton me-2 d-flex align-items-center' onClick={() => { setOpenUserDetails(false); setWarningData(null) }}><CaretLeftOutlined /><span>Back</span></Button>
                    {reportType !== "rating" && (<Button type='primary' className='me-2' style={{ backgroundColor: "gold", color: "#554d4d" }} onClick={() => handleWarning(user)}>Send Warning</Button>)}

                    {reportType == "comment" && (showDetails?.commentBy?.suspend == 2 ? suspendCheck : unsuspendCheck)}
                    {reportType == "chat" && (showDetails?.chatBy?.suspend == 2 ? suspendCheck : unsuspendCheck)}
                    {reportType == "group" && (showDetails?.groupBy?.suspend == 2 ? suspendCheck : unsuspendCheck)}
                    {reportType === "user" && (showDetails?.reportedTo?.suspend == 2 ? suspendCheck : unsuspendCheck)}
                    {reportType == "stream" && (showDetails?.streamId?.user?.suspend == 2 ? suspendCheck : unsuspendCheck)}
                    {reportType == "rating" && (showDetails?.rating?.status == 1 ? reviewDelete : reviewDeleted)}
                  </Col>
                </Row>
                {reportType == "user" &&
                  <Row className='mt-3 pt-3 Maincard'>
                    <Col xs={24} sm={24} md={6} lg={5} style={{ textAlign: "center" }}>
                      {user?.reportedTo?.profilePic ?
                        <img src={imagesrc} alt="avatar" type="square" style={{ width: "175px", borderRadius: "10px" }} />
                        : <Avatar size={64} icon={<UserOutlined />} style={{ width: "100px", height: "100px", fontSize: "80px" }} />}

                    </Col>
                    <Col xs={24} sm={12} md={5} lg={10} className='text-left my-lg-2'>
                      <p><span className='fw-bold'>Username:</span> {user?.reportedToData?.userName ?? '--'}</p>
                      <p><span className='fw-bold'>Name:</span> {user?.reportedToData?.firstName ?? "--"} {user?.reportedToData?.lastName}</p>
                      <p><span className='fw-bold'>Phone Number:</span> {user?.reportedToData?.countryCode} {user?.reportedToData?.phoneNumber ?? "--"}</p>
                    </Col>
                    <Col xs={24} sm={12} md={10} lg={9} className='my-lg-2'>
                      <p><span className='fw-bold'>DOB:</span> {moment(user?.reportedToData?.dob).format('MM/DD/YYYY')}</p>
                      <p><span className='fw-bold'>Email:</span> {user?.reportedToData?.email ?? "--"}</p>
                      {/* <p><span className='fw-bold'>Reports :</span> {user?.count ?? "--"}</p> */}
                      {warningData?.warningCount > 0 && <p><span className='fw-bold'>Warning Sent :</span>  <span style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }} onClick={() => setOpenWarningList(true)}>{warningData?.warningCount}</span></p>}
                    </Col>
                  </Row>
                }
                {console.log("file url outside", fileUrl)}
                {(reportType == "stream") &&
                  <Row className='my-3 p-3 Maincard'>
                    `                 {console.log("file url inside", fileUrl, user, user?.streamData?.streamType)}
                    {(user?.streamData?.thumbnail) &&
                      <Col xs={24} sm={24} md={7} lg={7} style={{ textAlign: "center" }}>
                        {/* <img src={imagesrc} alt="avatar" type="square" style={{ width: "175px", borderRadius: "10px" }} /> */}
                        <VideoPlayer videoUrl={user?.streamData?.video} thumbnailLink={imagesrc} streamType={user?.streamData?.streamType} />
                      </Col>}
                    <Col xs={24} sm={12} md={7} lg={12} className='text-left mt-1' >
                      <p><span className='fw-bold ms-2'></span> {showDetails?.streamId?.user?.firstName} {showDetails?.streamId?.user?.lastName}</p>
                      <p><span className='fw-bold ms-2'></span> {showDetails?.streamId?.title}</p>
                      <p><span className='fw-bold ms-2'></span> {showDetails?.streamId?.desc}</p>
                      {warningData?.warningCount > 0 && <p><span className='fw-bold ms-2'></span> Warnings : <span style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }} onClick={() => setOpenWarningList(true)}>{warningData?.warningCount}</span></p>}
                    </Col>
                  </Row>
                }
                {(reportType == "comment") &&
                  <Row className='my-3 py-2 Maincard' >
                    {(showDetails?.commentBy?.profilePic) &&
                      <Col xs={24} sm={24} md={3} lg={3} style={{ textAlign: "center" }}>
                        <Avatar size={64} icon={<img src={`${fileUrl}${showDetails?.commentBy?.profilePic}`} alt="avatar" type="square" style={{ borderRadius: "10px" }} />} />
                      </Col>}
                    <Col xs={24} sm={12} md={7} lg={12} className='d-flex justify-content-evenly flex-column' >
                      <div><span className='fw-bold '>Commented By </span> {showDetails?.commentBy?.firstName} {showDetails?.commentBy?.lastName}</div>
                      <div><span className='fw-bold '>Reported Comment </span> {showDetails?.desc}</div>
                      {warningData?.warningCount > 0 && <div><span className='fw-bold '>Warnings </span> : <span style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }} onClick={() => setOpenWarningList(true)}>{warningData?.warningCount}</span></div>}
                    </Col>
                  </Row>
                }
                {(reportType == "group") &&
                  <Row className='my-3 py-2 Maincard'>
                    {(user?.groupImage) &&
                      <Col xs={24} sm={24} md={3} lg={3} style={{ textAlign: "center" }}>
                        <Avatar size={64} icon={<img src={`${user?.groupImage}`} alt="avatar" type="square" style={{ borderRadius: "10px" }} />} />
                      </Col>}
                    <Col xs={24} sm={12} md={7} lg={12} className='d-flex justify-content-evenly flex-column' >
                      <div><span className='fw-bold '>Group </span> {user?.desc}</div>
                      <div><span className='fw-bold '>Created By </span> {user?.groupByData?.userName}</div>
                      {warningData?.warningCount > 0 && <div><span className='fw-bold '>Warnings </span> : <span style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }} onClick={() => setOpenWarningList(true)}>{warningData?.warningCount}</span></div>}
                    </Col>
                  </Row>
                }
                {(reportType == "rating") &&
                  <Row className='my-3 p-2 Maincard'>

                    <Col xs={24} sm={12} md={12} lg={12} className='d-flex justify-content-evenly flex-column' >
                      <p>Reviewed By <label style={{ fontWeight: 'bold' }}>{getRatedByFullName(showDetails)}</label></p>
                      <Row>
                        <Col xs={24} sm={24} md={24} lg={24}>

                          <Rate disabled value={showDetails?.rating?.rating ?? 0} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24}>

                          <p>{showDetails?.rating?.review ?? "--"}</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} className='d-flex justify-content-start' >
                      <p>Reviewed To <label style={{ fontWeight: 'bold' }}>{getRatedToFullName(showDetails)}</label></p>

                    </Col>
                  </Row>
                }
                {(reportType == "chat") &&
                  <Row className='my-3 py-2 Maincard'>
                    {(showDetails?.chatBy?.profilePic) &&
                      <Col xs={24} sm={24} md={3} lg={3} style={{ textAlign: "center" }}>
                        <Avatar size={64} icon={<img src={`${fileUrl}${showDetails?.chatBy?.profilePic}`} alt="avatar" type="square" style={{ borderRadius: "10px" }} />} />
                      </Col>}
                    <Col xs={24} sm={12} md={7} lg={12} className='d-flex justify-content-evenly flex-column' >
                      <div><span className='fw-bold '>Chat By </span> {showDetails?.chatBy?.firstName} {showDetails?.chatBy?.lastName}</div>
                      <div><span className='fw-bold'>Chat </span> {showDetails?.desc}</div>
                      {warningData?.warningCount > 0 && <div><span className='fw-bold'>Warnings </span> : <span style={{ textDecoration: "underline", color: "blue", cursor: "pointer" }} onClick={() => setOpenWarningList(true)}>{warningData?.warningCount}</span></div>}
                    </Col>
                  </Row>
                }
              </>
            )
          })}
          <div className='p-1'>
            <Row className='mt-4 mb-1'><h5 className='fw-bold'>Reports</h5>
            </Row>
            <Row className=''>
              {showUser?.length > 0 && showUser?.map((user) => (
                <>
                  <Col xs={24} sm={24} md={11} lg={11} className='p-3 m-1 Maincard'>
                    <Row>
                      <Col xs={24} sm={24} md={5} lg={5}>
                        {user?.reportedByData?.profilePic ? <img src={`${fileUrl}${user?.reportedByData?.profilePic}`} alt="" style={{ width: "60px", height: "60px", borderRadius: "50%" }} />
                          : <Avatar size={44} icon={<UserOutlined />} />}

                      </Col>
                      <Col xs={24} sm={24} md={19} lg={19}>
                        <h6 className='fw-bold '>{user?.reportedByData?.firstName ?? "--"} {user?.reportedByData?.lastName}</h6>
                        <p >{user?.reason ?? "--"}</p>
                      </Col>
                    </Row>
                  </Col>
                </>
              ))}
            </Row>
          </div>
          <Modal
            centered
            open={openDelModal}
            onCancel={() => setOpenDelModal(false)}
            footer={null}
            width={350}
          >
            <div className='text-center'>
              <img src={logoDel} alt="delete_icon" style={{ width: "25%" }} />
            </div>
            <h5 className='text-center'>Delete User</h5>
            <p>Are you sure you want to Delete this User?</p>
            <div>
              <Button type="primary" className='me-2 w-48' style={{ backgroundColor: "#6240C9" }} onClick={onDeleteOk}>Delete</Button>
              <Button danger type="primary" className='w-48 ' onClick={() => { setOpenDelModal(false); setDelId(null) }}>Cancel</Button>
            </div>
          </Modal>

          <Modal
            centered
            open={openDelModal}
            onCancel={() => setOpenDelModal(false)}
            footer={null}
            width={350}
          >
            <div className='text-center'>
              <img src={logoDel} alt="delete_icon" style={{ width: "25%" }} />
            </div>
            <h5 className='text-center'>Delete Rating</h5>
            <p>Are you sure you want to Delete rating?</p>
            <div>
              <Button type="primary" className='me-2 w-48' style={{ backgroundColor: "#6240C9" }} onClick={onDeleteOk}>Delete</Button>
              <Button danger type="primary" className='w-48 ' onClick={() => { setOpenDelModal(false); setDelId(null) }}>Cancel</Button>
            </div>
          </Modal>
          <Modal
            centered
            open={openSuspendModal}
            onCancel={() => { setOpenSuspendModal(false); setSuspendId(null) }}
            footer={null}
            width={350}
          >
            <h5 className='text-center'>Suspend User</h5>
            <p>Are you sure you want to Suspend this User?</p>
            <div>
              <Button type="primary" className='me-2 w-48' style={{ backgroundColor: "#6240C9" }} onClick={onSuspendOk}>Suspend</Button>
              <Button danger type="primary" className='w-48 ' onClick={() => { setOpenSuspendModal(false); setSuspendId(null) }}>Cancel</Button>
            </div>
          </Modal>
          <Modal
            centered
            open={openUnsuspendModal}
            onCancel={() => { setOpenUnsuspendModal(false); setUnsuspend(null) }}
            footer={null}
            width={350}
          >
            <h5 className='text-center'>Unsuspend User</h5>
            <p>Are you sure you want to Unsuspend this User?</p>
            <div>
              <Button type="primary" className='me-2 w-48' style={{ backgroundColor: "#6240C9" }} onClick={onUnsuspendOk}>Unsuspend</Button>
              <Button danger type="primary" className='w-48 ' onClick={() => { setOpenUnsuspendModal(false); setUnsuspend(null) }}>Cancel</Button>
            </div>
          </Modal>
          <Modal
            centered
            open={openWarningList}
            onCancel={() => { setOpenWarningList(false) }}
            footer={null}
            width={350}
          // height={500}
          // style={{overflowY:"scroll", minHeight:"500px"}}
          >
            <h5 className='text-center p-2 fw-bold'>Warning List</h5>
            {warningData?.warningCount > 0 && warningData?.data.map(item =>
              <Card>
                <p><span className='fw-bold'>Title</span> : {item?.title}</p>
                <p><span className='fw-bold'>Message</span> : {item?.message}</p>
              </Card>
            )}
            <div className='text-center mt-3 mb-2'>
              <Button danger type="primary" className='w-48 ' onClick={() => { setOpenWarningList(false) }}>Cancel</Button>
            </div>
          </Modal>
          {warningModal && <ModalQuestion
            form={form}
            open={warningModal}
            title="Send Warning"
            data={warning}
            type="Send"
            onCancel={() => { form.resetFields(); setWarning(''); setWarningModal(false); }}
            onFinish={handleWarningFinish}
          />
          }
        </>
      }
    </div>
  )
}

// Fulll screen video player for user reporting detail part
const VideoPlayer = ({ videoUrl, thumbnailLink, streamType }) => {
  const [showPlayer, setShowPlayer] = useState(false);
  const elementRef = useRef(null);

  const requestFullscreen = () => {
    setShowPlayer(true)
  };
  console.log("Reached inside video player wih streamType", streamType, videoUrl)
  let videoLink;
  if (videoUrl) {
    if (streamType == "pre") videoLink = fileUrl + videoUrl;
    else videoLink = `${fileUrl}streams/` + videoUrl;
    console.log("videoLink11111111111", videoLink, streamType)
  }

  useEffect(() => {
    if (showPlayer) {
      if (elementRef.current) {
        if (elementRef.current.requestFullscreen) {
          elementRef.current.requestFullscreen();
        } else if (elementRef.current.mozRequestFullScreen) { // Firefox
          elementRef.current.mozRequestFullScreen();
        } else if (elementRef.current.webkitRequestFullscreen) { // Chrome, Safari, and Opera
          elementRef.current.webkitRequestFullscreen();
        } else if (elementRef.current.msRequestFullscreen) { // IE/Edge
          elementRef.current.msRequestFullscreen();
        }
        // setShowPlayer(true)
      }
    }
  }, [showPlayer])

  return (
    <div className="video-container d-flex justify-content-center flex-column align-items-center">
      {(showPlayer) ?
        <div className='mt-3'>
          {videoLink && <video controls autoPlay ref={elementRef} style={{ width: "200px", height: "200px" }}>
            <source src={videoLink} type="video/mp4" />
            Your browser does not support the video tag.
          </video>}
        </div>
        :
        <>
          <img src={thumbnailLink} alt="avatar" type="square" style={{ width: "175px", borderRadius: "10px" }} />
          <Button className="mt-2" onClick={() => requestFullscreen()}>View Video</Button>
        </>
      }
    </div>
  );
};

export default UserReporting