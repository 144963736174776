import React, { useState } from 'react';
import { Layout, Menu, Button, Drawer } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import logoNoti from '../assets/images/m_notification_light.png';
import logoNotiDark from '../assets/images/m-notification-dark.png';
import logoOverview from '../assets/images/m_dashboard_light.png';
import logoOverviewDark from '../assets/images/m-dashboard-1.png';
import logoFaq from '../assets/images/m_faq.png';
import logoFaqDark from '../assets/images/faq-1.png';
import logoUser from '../assets/images/users.png';
import logoUserDark from '../assets/images/users-1.png';
import logoContact from '../assets/images/m-contact.png';
import logoContactDark from '../assets/images/m-contact-1.png';
import logoHow from '../assets/images/m-how.png';
import logoHowDark from '../assets/images/m-how-1.png';
import logoIntro from '../assets/images/m-introfile-richtext-fill.png';
import logoIntroDark from '../assets/images/m-introfile-richtext-fill-1.png';
import logoPrivacy from '../assets/images/m-privacyPath 38898.png';
import logoPrivacyDark from '../assets/images/m-privacyPath 38899.png';
import logoTerm from '../assets/images/m-termfile-text.png';
import logoTermDark from '../assets/images/m-termfile-text-1.png';
import settingDark from "../assets/images/settings.png"
import settingLight from "../assets/images/settingsLight.png"
import adsIconLight from "../assets/images/advertise.png"
import adsIconDark from "../assets/images/advertiseDark.png"
import giftDark from "../assets/images/giftDark.png"
import giftLight from "../assets/images/giftLight.png"
import moneybagLight from "../assets/images/moneybagLight.png"
import analyticsLight from "../assets/images/analyticsLight.png"
import analyticsDark from "../assets/images/analyticsDark.png"
import payReqDark from "../assets/images/payReqDark.png"
import payReqLight from "../assets/images/payReqLight.png"
import HostDark from "../assets/images/HostDark.png"
import HostLight from "../assets/images/HostLight.png"
import affi from "../assets/images/m_affi.png"
import affi_dark from "../assets/images/m_affi_Dark.png"
import cat from "../assets/images/m_Cat.png"
import cat_dark from "../assets/images/m_Cat_Dark.png"
import stat from "../assets/images/m_Static.png"
import stat_dark from "../assets/images/m_Static_Dark.png"
import logo_lead from "../assets/images/m_Lead.png"
import logo_lead_dark from "../assets/images/m_Lead_Dark.png"
import logo_promoter from "../assets/images/m_Promoters.png"
import logo_promoter_dark from "../assets/images/m_Promoters_dark.png"
import dynamic from "../assets/images/m_Desktop.png"
import dynamic_dark from "../assets/images/m_Desktop_Dark.png"
import app_light from "../assets/images/m_app_light.png"
import app_dark from "../assets/images/m_app_dark.png"
import content_dark from "../assets/images/m_content_dark.png"
import content_light from "../assets/images/m_content_light.png"
import repo_light from "../assets/images/m_repo_light.png"
import repo_dark from "../assets/images/m_repo_dark.png"
import del_dark from "../assets/images/m_delete_dark.png"
import del_light from "../assets/images/m_del_light.png"
import backup_light from "../assets/images/m_backup_light1.png"
import backup_dark from "../assets/images/m_backup_dark1.png"
import web_dark from "../assets/images/m_web_dark.png"
import web_light from "../assets/images/m_web_light.png"
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';


function MenuApp() {
  const { Sider } = Layout;
  const location = useLocation();
  const [collapsedDrawer, setCollapsedDrawer] = useState(false)
  const [collapsed, setCollapsed] = useState(false)
  const [selectedKeys, setSelectedKeys] = useState([location?.pathname]);

  const handleMenuClick = (e) => {
    setSelectedKeys([e.key]);
  };
  const handleBreakpoint = (broken) => {
    if (broken) {
      setCollapsed(true); // if the screen is broken (md and below), collapse the sider
    } else {
      setCollapsed(false); // if the screen is not broken (lg and above), expand the sider
    }
  };
  return (
    <>
      {collapsed &&
        <Button type="text" onClick={() => setCollapsedDrawer(!collapsedDrawer)} className="ps-3" icon={collapsedDrawer ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} />
      }
      <Sider width={230} theme="light"
        trigger
        breakpoint="md"
        collapsedWidth="0"
        collapsible
        onBreakpoint={handleBreakpoint}>
        <Menu mode="inline" theme="light"
          defaultSelectedKeys={[location?.pathname]}
          style={{ paddingTop: "10px" }}
          // selectedKeys={selectedKeys}
          onClick={handleMenuClick}>
          <Menu.Item key="/overview" icon={<img className='w-10' src={selectedKeys[0] == '/overview' ? logoOverviewDark : logoOverview} alt="icon" />}>
            <Link to="/overview">Overview</Link>
          </Menu.Item>
          <Menu.Item key="/custom_notification" icon={<img className='w-10' src={selectedKeys[0] == '/custom_notification' ? logoNotiDark : logoNoti} alt="icon" />}>
            <Link to="/custom_notification">Custom Notification</Link>
          </Menu.Item>
          <Menu.Item key="/user_reporting" icon={<img className='w-10' src={selectedKeys[0] == '/user_reporting' ? logoUserDark : logoUser} alt="icon" />}>
            <Link to="/user_reporting">User Reporting</Link>
          </Menu.Item>
          <Menu.Item key="/categories" icon={<img className='w-10' src={selectedKeys[0] == '/categories' ? cat_dark : cat} alt="icon" />}>
            <Link to="/categories">Categories</Link>
          </Menu.Item>
          {/* <Menu.SubMenu key="affiliation" title="Affiliation" icon={<img className='w-10' src={affi} alt="icon" />} >
            <Menu.Item key="/affiliation_dashboard" icon={<img className='w-10' src={selectedKeys[0] == '/affiliation_dashboard' ? logoOverviewDark : logoOverview} alt="icon" />}>
              <Link to="/affiliation_dashboard">Dashboard</Link>
            </Menu.Item>
            <Menu.Item key="/promoters" icon={<img className='w-10' src={selectedKeys[0] == '/promoters' ? logo_promoter_dark : logo_promoter} alt="icon" />}>
              <Link to="/promoters">Promoters</Link>
            </Menu.Item>
            <Menu.Item key="/leads" icon={<img className='w-10' src={selectedKeys[0] == '/leads' ? logo_lead : logo_lead_dark} alt="icon" />}>
              <Link to="/leads">Leads</Link>
            </Menu.Item>
          </Menu.SubMenu> */}
          <Menu.SubMenu key="dynamic_subscription" title="Dynamic Subscription" icon={<img className='w-10' src={dynamic} alt="icon" />} >
          <Menu.Item key="/dynamic_subscription_dashboard" icon={<img className='w-10' src={selectedKeys[0] == '/dynamic_subscription_dashboard' ? logoOverviewDark : logoOverview} alt="icon" />}>
            <Link to="/dynamic_subscription_dashboard">Dashboard</Link>
          </Menu.Item>
          <Menu.Item key="/dynamic_subscription" icon={<img className='w-10' src={selectedKeys[0] == '/dynamic_subscription' ? dynamic_dark : dynamic} alt="icon" />}>
            <Link to="/dynamic_subscription">Plans</Link>
          </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu key="static" title="Static Pages" icon={<img className='w-10' src={stat} alt="icon" />} >
            <Menu.Item key="/faq" icon={<img className='w-10' src={selectedKeys[0] == '/faq' ? logoFaqDark : logoFaq} alt="icon" />}>
              <Link to="/faq">FAQ</Link>
            </Menu.Item>
            <Menu.Item key="/termsofservice" icon={<img className='w-10' src={selectedKeys[0] == '/termsofservice' ? logoTermDark : logoTerm} alt="icon" />}>
              <Link to="/termsofservice">Terms of Service</Link>
            </Menu.Item>
            <Menu.Item key="/privacypolicy" icon={<img className='w-10' src={selectedKeys[0] == '/privacypolicy' ? logoPrivacyDark : logoPrivacy} alt="icon" />}>
              <Link to="/privacypolicy">Privacy Policy</Link>
            </Menu.Item>
            <Menu.Item key="/aknowledgement" icon={<img className='w-10' src={selectedKeys[0] == '/aknowledgement' ? logoHowDark : logoHow} alt="icon" />}>
              <Link to="/aknowledgement">Aknowlegdement</Link>
            </Menu.Item>
            <Menu.Item key="/about_us" icon={<img className='w-10' src={selectedKeys[0] == '/about_us' ? logoHowDark : logoHow} alt="icon" />}>
              <Link to="/about_us">About US</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key="/contactus" icon={<img className='w-10' src={selectedKeys[0] == '/contactus' ? logoContactDark : logoContact} alt="icon" />}>
            <Link to="/contactus">Contact Us</Link>
          </Menu.Item>
          <Menu.Item key="/streaming_analysis" icon={<img className='w-10' src={selectedKeys[0] == '/streaming_analysis' ? analyticsDark : analyticsLight} alt="icon" />}>
            <Link to="/streaming_analysis">Streaming Analysis</Link>
          </Menu.Item>
          <Menu.SubMenu key="tip" title="Tips" icon={<img className='w-10' src={moneybagLight} alt="icon" />} >
            <Menu.Item key="/tip_analytics" icon={<img className='w-10' src={selectedKeys[0] == '/tip_analytics' ? analyticsDark : analyticsLight} alt="icon" />}>
              <Link to="/tip_analytics">Analyitcs</Link>
            </Menu.Item>
            {/* <Menu.Item key="/tip_payout_request" icon={<img className='w-10' src={selectedKeys[0] == '/tip_payout_request' ? payReqDark : payReqLight} alt="icon" />}>
              <Link to="/tip_payout_request">Payout Requests</Link>
            </Menu.Item> */}
            <Menu.Item key="/" icon={<img className='w-10' src={selectedKeys[0] == '/' ? HostDark : HostLight} alt="icon" />}>
              <Link to="/tip_host">Host (Tips)</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key="/advertisement" icon={<img className='w-10' src={selectedKeys[0] == '/advertisement' ? adsIconDark : adsIconLight} alt="icon" />}>
            <Link to="/advertisement">Advertisement</Link>
          </Menu.Item>


          <Menu.SubMenu key="reward" title="Rewards" icon={<img className='w-10' src={giftLight} alt="icon" />} >
            <Menu.Item key="/rewards" icon={<img className='w-10' src={selectedKeys[0] == '/rewards' ? HostDark : HostLight} alt="icon" />}>
              <Link to="/rewards">Host</Link>
            </Menu.Item>
            <Menu.Item key="/paid_rewards" icon={<img className='w-10' src={selectedKeys[0] == '/paid_rewards' ? giftDark : giftLight} alt="icon" />}>
              <Link to="/paid_rewards">Rewards (Paid)</Link>
            </Menu.Item>
          </Menu.SubMenu>

          {/* <Menu.SubMenu key="Content Management" title="Content Management" icon={<img className='w-10' src={content_light} alt="icon" />} >
            <Menu.Item key="/repository" icon={<img className='w-10' src={selectedKeys[0] == '/repository' ? repo_dark : repo_light} alt="icon" />}>
              <Link to="/repository">Data Repository</Link>
            </Menu.Item>
            <Menu.Item key="/backup" icon={<img className='w-10' src={selectedKeys[0] == '/backup' ? backup_dark : backup_light} alt="icon" />}>
              <Link to="/backup">Data Backup</Link>
            </Menu.Item>
            
            <Menu.Item key="/app_customisation" icon={<img className='w-10' src={selectedKeys[0] == '/app_customisation' ? app_dark : app_light} alt="icon" />}>
              <Link to="/app_customisation">App Customization (Paid)</Link>
            </Menu.Item>
          </Menu.SubMenu> */}

          {/* <Menu.Item key="/delete_account" icon={<img className='w-10' src={selectedKeys[0] == '/delete_account' ? del_dark : del_light} alt="icon" />}>
            <Link to="/delete_account">Delete Request</Link>
          </Menu.Item> */}

          <Menu.Item key="/settings" icon={<img className='w-10' src={selectedKeys[0] == '/settings' ? settingDark : settingLight} alt="icon" />}>
            <Link to="/settings">Settings</Link>
          </Menu.Item>

        </Menu>
      </Sider>

      <Drawer placement="right" onClose={() => setCollapsedDrawer(!collapsedDrawer)} open={collapsedDrawer} style={{ backgroundColor: 'white' }} >
      <Menu mode="inline" theme="light"
          defaultSelectedKeys={[location?.pathname]}
          style={{ paddingTop: "10px" }}
          // selectedKeys={selectedKeys}
          onClick={handleMenuClick}>
          <Menu.Item key="/overview" icon={<img className='w-10' src={selectedKeys[0] == '/overview' ? logoOverviewDark : logoOverview} alt="icon" />}>
            <Link to="/overview">Overview</Link>
          </Menu.Item>
          <Menu.Item key="/custom_notification" icon={<img className='w-10' src={selectedKeys[0] == '/custom_notification' ? logoNotiDark : logoNoti} alt="icon" />}>
            <Link to="/custom_notification">Custom Notification</Link>
          </Menu.Item>
          <Menu.Item key="/user_reporting" icon={<img className='w-10' src={selectedKeys[0] == '/user_reporting' ? logoUserDark : logoUser} alt="icon" />}>
            <Link to="/user_reporting">User Reporting</Link>
          </Menu.Item>
          <Menu.Item key="/categories" icon={<img className='w-10' src={selectedKeys[0] == '/categories' ? cat_dark : cat} alt="icon" />}>
            <Link to="/categories">Categories</Link>
          </Menu.Item>
          {/* <Menu.SubMenu key="affiliation" title="Affiliation" icon={<img className='w-10' src={affi} alt="icon" />} >
            <Menu.Item key="/affiliation_dashboard" icon={<img className='w-10' src={selectedKeys[0] == '/affiliation_dashboard' ? logoOverviewDark : logoOverview} alt="icon" />}>
              <Link to="/affiliation_dashboard">Dashboard</Link>
            </Menu.Item>
            <Menu.Item key="/promoters" icon={<img className='w-10' src={selectedKeys[0] == '/promoters' ? logo_promoter_dark : logo_promoter} alt="icon" />}>
              <Link to="/promoters">Promoters</Link>
            </Menu.Item>
            <Menu.Item key="/leads" icon={<img className='w-10' src={selectedKeys[0] == '/leads' ? logo_lead : logo_lead_dark} alt="icon" />}>
              <Link to="/leads">Leads</Link>
            </Menu.Item>
          </Menu.SubMenu> */}
          <Menu.Item key="/dynamic_subscription" icon={<img className='w-10' src={selectedKeys[0] == '/dynamic_subscription' ? dynamic_dark : dynamic} alt="icon" />}>
            <Link to="/dynamic_subscription">Dynamic Subscription</Link>
          </Menu.Item>
          <Menu.SubMenu key="static" title="Static Pages" icon={<img className='w-10' src={stat} alt="icon" />} >
            <Menu.Item key="/faq" icon={<img className='w-10' src={selectedKeys[0] == '/faq' ? logoFaqDark : logoFaq} alt="icon" />}>
              <Link to="/faq">FAQ</Link>
            </Menu.Item>
            <Menu.Item key="/termsofservice" icon={<img className='w-10' src={selectedKeys[0] == '/termsofservice' ? logoTermDark : logoTerm} alt="icon" />}>
              <Link to="/termsofservice">Terms of Service</Link>
            </Menu.Item>
            <Menu.Item key="/privacypolicy" icon={<img className='w-10' src={selectedKeys[0] == '/privacypolicy' ? logoPrivacyDark : logoPrivacy} alt="icon" />}>
              <Link to="/privacypolicy">Privacy Policy</Link>
            </Menu.Item>
            <Menu.Item key="/aknowledgement" icon={<img className='w-10' src={selectedKeys[0] == '/aknowledgement' ? logoHowDark : logoHow} alt="icon" />}>
              <Link to="/aknowledgement">Aknowlegdement</Link>
            </Menu.Item>
            <Menu.Item key="/about_us" icon={<img className='w-10' src={selectedKeys[0] == '/about_us' ? logoHowDark : logoHow} alt="icon" />}>
              <Link to="/about_us">About US</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key="/contactus" icon={<img className='w-10' src={selectedKeys[0] == '/contactus' ? logoContactDark : logoContact} alt="icon" />}>
            <Link to="/contactus">Contact Us</Link>
          </Menu.Item>
          <Menu.Item key="/streaming_analysis" icon={<img className='w-10' src={selectedKeys[0] == '/streaming_analysis' ? analyticsDark : analyticsLight} alt="icon" />}>
            <Link to="/streaming_analysis">Streaming Analysis</Link>
          </Menu.Item>
          <Menu.SubMenu key="tip" title="Tips" icon={<img className='w-10' src={moneybagLight} alt="icon" />} >
            <Menu.Item key="/tip_analytics" icon={<img className='w-10' src={selectedKeys[0] == '/tip_analytics' ? analyticsDark : analyticsLight} alt="icon" />}>
              <Link to="/tip_analytics">Analytics</Link>
            </Menu.Item>
            <Menu.Item key="/tip_payout_request" icon={<img className='w-10' src={selectedKeys[0] == '/tip_payout_request' ? payReqDark : payReqLight} alt="icon" />}>
              <Link to="/tip_payout_request">Payout Requests</Link>
            </Menu.Item>
            <Menu.Item key="/" icon={<img className='w-10' src={selectedKeys[0] == '/' ? HostDark : HostLight} alt="icon" />}>
              <Link to="/tip_host">Host (Tips)</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key="/advertisement" icon={<img className='w-10' src={selectedKeys[0] == '/advertisement' ? adsIconDark : adsIconLight} alt="icon" />}>
            <Link to="/advertisement">Advertisement</Link>
          </Menu.Item>


          <Menu.SubMenu key="reward" title="Rewards" icon={<img className='w-10' src={giftLight} alt="icon" />} >
            <Menu.Item key="/rewards" icon={<img className='w-10' src={selectedKeys[0] == '/rewards' ? HostDark : HostLight} alt="icon" />}>
              <Link to="/rewards">Host</Link>
            </Menu.Item>
            <Menu.Item key="/paid_rewards" icon={<img className='w-10' src={selectedKeys[0] == '/paid_rewards' ? giftDark : giftLight} alt="icon" />}>
              <Link to="/paid_rewards">Rewards (Paid)</Link>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu key="Content Management" title="Content Management" icon={<img className='w-10' src={content_light} alt="icon" />} >
            <Menu.Item key="/repository" icon={<img className='w-10' src={selectedKeys[0] == '/repository' ? repo_dark : repo_light} alt="icon" />}>
              <Link to="/repository">Data Repository</Link>
            </Menu.Item>
            <Menu.Item key="/backup" icon={<img className='w-10' src={selectedKeys[0] == '/backup' ? backup_dark : backup_light} alt="icon" />}>
              <Link to="/backup">Data Backup</Link>
            </Menu.Item>
            {/* <Menu.Item key="/web_customisation" icon={<img className='w-10' src={selectedKeys[0] == '/web_customisation' ? web_dark : web_light} alt="icon" />}>
              <Link to="/web_customisation">Web Customization</Link>
            </Menu.Item> */}
            <Menu.Item key="/app_customisation" icon={<img className='w-10' src={selectedKeys[0] == '/app_customisation' ? app_dark : app_light} alt="icon" />}>
              <Link to="/app_customisation">App Customization (Paid)</Link>
            </Menu.Item>
          </Menu.SubMenu>

          {/* <Menu.Item key="/delete_account" icon={<img className='w-10' src={selectedKeys[0] == '/delete_account' ? del_dark : del_light} alt="icon" />}>
            <Link to="/delete_account">Delete Request</Link>
          </Menu.Item> */}

          <Menu.Item key="/settings" icon={<img className='w-10' src={selectedKeys[0] == '/settings' ? settingDark : settingLight} alt="icon" />}>
            <Link to="/settings">Settings</Link>
          </Menu.Item>

        </Menu>
      </Drawer>
    </>
  );
}

export default MenuApp