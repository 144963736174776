import { Button, Form, Input, Modal, message } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React from 'react'

function ModalQuestion({ form, open, title, data = 'null', onCancel, onFinish, type }) {
  const [formm] = Form.useForm();
  const onModalFinish = (values) => {
    console.log('values in ',values)
    if(!(values.Title).trim() || !(values?.Message).trim()){
      message.error("Both the Fields are Required")
      return false
    }
    return onFinish(values)
  }
  form.setFieldsValue({
    Name: data?.title,
    Message: data?.message,
  });
  return (
    <>
      <Modal
        title={title}
        centered
        open={open}
        key={open}
        onCancel={() => onCancel()}
        footer={null}
        width={400}
      >
        <Form form={form} onFinish={onModalFinish} >
          <Form.Item name="Title"
            initialValue={data?.title}
            rules={[{ required: true, message: `Please Fill The Field` }]} label={type == "Send" ? "Title" : `Title`} labelCol={{ span: 24 }}>
            <Input placeholder={type == "Send" ? "Title" : "Name"} />
          </Form.Item>
          <Form.Item name="Message"
            initialValue={data?.message}
            rules={[{ required: true, message: 'Please Enter Your Message!' }]} label="Description" labelCol={{ span: 24 }}>
            <TextArea placeholder='Description' rows={5} />
          </Form.Item>
          <Form.Item className='mx-auto'>
            <Button type="primary" className='me-2 w-48' style={{ backgroundColor: "#6240C9" }} htmlType='submit'>{type}</Button>
            <Button danger type="primary" className='w-48 ' onClick={() => onCancel()}>Cancel</Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default ModalQuestion